<template>
  <div class="mt-0">
    <h2>
      {{ opInfo.firstname }} {{ opInfo.lastname }}
    </h2>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'PersonalInfoSection',
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
  },
}
</script>

<style scoped>
</style>
