<template>
  <div>
    <b-card class="full-width">
      <personalInfoSection />
      <addressInfoSection />
    </b-card>
  </div>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import personalInfoSection from '@/components/operatives/operativedetailssections/PersonalInfoSection.vue'
import addressInfoSection from '@/components/operatives/operativedetailssections/AddressInfoSection.vue'

export default {
  name: 'OperativeDetails',
  components: {
    BCard,
    personalInfoSection,
    addressInfoSection,
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>
</style>
