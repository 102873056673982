<template>
  <div>
    <b-card>
      <operativeDetails />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import operativeDetails from '@/components/operatives/OperativeDetails.vue'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    operativeDetails,
  },
}
</script>

<style>

</style>
