<template>
  <div>
    <b-row class="mt-2 mb-1">
      <b-col>
        <span v-if="address.addressLine1 !==''">
          {{ address.addressLine1 }}<br>
        </span>
        <span v-if="address.addressLine2 !==''">
          {{ address.addressLine2 }}<br>
        </span>
        <span v-if="address.city !==''">
          {{ address.city }}<br>
        </span>
        {{ opInfo.postcode }}<br>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'AddressInfoSection',
  components: {
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
      },
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
  },
  created() {
    if (this.opInfo.address != null) {
      [this.address.addressLine1, this.address.addressLine2, this.address.city] = this.opInfo.address.toString().split(/\n/)
    }
  },
}
</script>

<style scoped>
</style>
